import React from 'react'
import { navigate } from 'gatsby-link'

import SEO from '../components/seo'
import Layout from '../components/layout'

import './thanks.scss'

function Thanks() {
  setTimeout(() => {
    navigate('/')
  }, 6000)
  return (
    <Layout>
      <SEO title="Contact" />
      <section className="thanks-container">
        Thanks for contacting us. We will be in touch.
      </section>
    </Layout>
  )
}

export default Thanks;